<template>
  <div>
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="searchQuery"
              :placeholder="$t('common.list.selectDistributor')"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-card class="mt-10">
      <!-- table -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <template #[`item.nicename`]="{item}">
          <router-link :to="{ name: 'distributor-view', params: { id: item.id } }">
            {{ item.nicename }}
          </router-link>
        </template>

        <template #[`item.status`]="{item}">
          {{ getName(item.status) }}
        </template>

        <!-- user -->
        <template #[`item.owners`]="{item}">
          <span
            v-if="item.owners.length"
            class="font-weight-medium text-decoration-none"
          >
            {{ item.owners[0].email }}
          </span>
        </template>

        <!-- date -->
        <template #[`item.created_at`]="{item}">
          <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
        </template>

        <!-- issued -->
        <template #[`item.issued`]="{item}">
          <span class="text-no-wrap">{{ item.issued ? "Yes" : "No" }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="downloadPdf(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiCloudDownloadOutline }}
                    </v-icon>
                    <span>Download</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="setRefundAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Refund</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="setPaymentAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Payment</span>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  link
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <refund-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadInvoices(); aside = false"
    ></refund-aside>

    <payment-aside
      v-if="paymentAside"
      v-model="paymentAside"
      :resource="profileResource"
      @changed="loadInvoices(); paymentAside = false"
    ></payment-aside>
  </div>
</template>

<script>
import { useDistributor } from '@/composables'
import {
mdiCloudDownloadOutline,
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

import axios from '@axios'
import _ from 'lodash'
import PaymentAside from '../invoice-resource/PaymentAside.vue'
import RefundAside from '../invoice-resource/RefundAside.vue'
import useInoviceList from './useInoviceList'

export default {
  components: {
    RefundAside,
    PaymentAside,
  },

  setup() {
    const {
      tableList, tableColumns, tableTotal, tableSelectedData, searchQuery, options, loadInvoices, loading,
    } = useInoviceList()

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const aside = ref(false)
    const resource = ref(null)
    const setRefundAside = item => {
      aside.value = true
      resource.value = item
    }

    const paymentAside = ref(false)
    const profileResource = ref(null)
    const setPaymentAside = item => {
      paymentAside.value = true
      profileResource.value = item
    }

    const { distributorOptions } = useDistributor()

    const statusOptions = ref([
      {
        name: 'Partially paid',
        value: 'partially_paid',
      },
      {
        name: 'Paid',
        value: 'paid',
      },
      {
        name: 'Wait For Payment',
        value: 'wait_for_payment',
      },
    ])

    const getName = value =>

      // return statusOptions.value
      //   .filter(status => status.value === value)
      //   .map(status => status.name)
      //   .reduce((a, b) => {
      //     return b
      //   }, '')
      _.get(
        _.find(statusOptions.value, status => status.value === value),
        'name',
      )

    const downloadPdf = async item => {
      axios({
        method: 'get',
        url: `/admin/invoices/${item.id}/download.pdf`,
        responseType: 'arraybuffer',
      }).then(res => {
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'invoice.pdf'
        link.click()
      })
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      searchQuery,

      loadInvoices,
      setRefundAside,
      aside,
      resource,

      setPaymentAside,
      paymentAside,
      profileResource,

      options,
      loading,
      actions,
      selectedAction,

      t,
      formatDate,
      distributorOptions,
      statusOptions,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCloudDownloadOutline,
        mdiMenuUp,
        mdiMenuDown,
      },
      getName,
      downloadPdf,
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
